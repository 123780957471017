@import '../../App.scss';

.password-reset-container {
  width: 70%;
  margin: 0 auto;
  .success-alert {
    background-color: orange;
    // border: 1px solid green;
    padding: 15px;
  }
  div {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .loading {
    color: white;
    min-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    .MuiCircularProgress-colorPrimary {
      color: white !important;
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .password-reset-container {
    width: 40%;
  }
}