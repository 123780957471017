.loader {
  &__wrapper {
    position: fixed;
    z-index: 9999;
    height: 2em;
    width: 2em;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    width: 100%;
  }
  &__wrapper:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }
  &__wrapper.light:before {
    background-color: rgba(0, 0, 0, 0.2);
  }
  &__graphic {
    color: orange;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: 50%;
    justify-content: center;
    font-weight: bold;
    z-index: 1000;
  }
  &__spinner {
    width: 70px;
    height: 70px;
  }
}