@import '../../App.scss';

#event-prime {
  background-color: #4da02e;
  td {
    color: white !important;
  }
}

.event-mergable {
  background-color: #d3e8cb !important;
}

.share-cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .action-icon {
    display: block;
    fill: lightgray;
    cursor: pointer;
    margin: 1px;
    &:hover {
      fill: orange;
    }
  }
}

.event-list-placeholder {
  position: relative;
  img {
    max-width: 100%;
    width: 100%;
  }
  &__overlay {
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.8);
    &__action {
      margin-top: 100px;
      z-index: 3;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      color: white;
      font-weight: bold;
      align-items: center;
      div {
        padding: 25px 0px;
      }
      button {
        height: 50px;
      }
      @media (max-width: $breakpoint-mobile) {
        margin-top: 0;
        padding: 1rem;
        div {
          padding: 10px 0px;
        }
      }
    }
  }
}

.event-report-share {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &__icon {
    margin-right: 10px;
  }
  &__share-type {
    display: flex;
    align-items: center;
    border: 1px solid lightgrey;
    padding: 20px;
    border-radius: 10px;
    width: 40%;
    cursor: pointer;
    &:hover {
      border: 1px solid orange;
      color: orange;
    }
  }
}

.events-form {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .radio-picker {
    display: flex;
    flex-direction: row;
  }
  .time-fields {
    input {
      padding-left: 0px !important;
      padding-top: 11px;
      padding-bottom: 11px;
    }
    .MuiOutlinedInput-notchedOutline {
      border: none;
      border-bottom: 1px solid grey;
      border-radius: 0;
    }
  }
  .form-fields {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .short {
      width: 50%;
    }
  }
  // .time-fields > div {
  //   margin-right: 15px;
  //   text-align: center;
  // }
  .file-label {
    margin-left: 15px;
  }
}
