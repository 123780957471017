$breakpoint-mobile: 480px;
$breakpoint-tablet: 1028px;

html,
body,
#root {
  height: 100%;
  // TODO: change global font-family, main areas to address are in the report/testimonial image downloads
  // font-family: 'Sora';
}

.App {
  // text-align: center;
  height: 100%;
}

.upgrade-helper-text {
  background: black;
  color: white !important;
  text-align: center;
  margin-bottom: 25px !important;
}

.modal-close-icon {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.modal-close-icon#upgrade-page {
  fill: white;
}

.range-stat-display {
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
  width: 100%;
  &__labels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  .range-stat-container {
    display: flex;
    width: 100%;
    background-color: grey;
    height: 1px;
    align-items: center;
    position: relative;
    &__0-to-min {
      height: 1px;
    }
    &__min-to-max {
      height: 10px;
      background: orange;
      align-self: center;
      opacity: 0.5;
    }
    &__average {
      background: orange;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      position: absolute;
      left: 50%;
    }
    &__average-to-max {
      background-color: orange;
      height: 10px;
      opacity: 0.5;
    }
    &__max-to-end {
      height: 0px;
    }
  }
}

// .my-alert {
//   top: 5px;
//   right: 5px;
//   z-index: 99999999;
//   position: fixed;
//   max-width: 300px;
//   width: 300px;
//   height: 100px;
//   max-height: 100px;
//   overflow: scroll;
//   text-align: left;
//   .showing {
//     animation: slide-in 1s forwards;
//     -webkit-animation: slide-in 1s forwards;
//   }
// }

@keyframes slide-in {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(100%);
  }
}

.orange-button {
  background-color: orange !important;
  border: 1px solid orange !important;
  border-radius: 0px !important;
  color: white !important;
  cursor: pointer;
}

.orange-button.big {
  width: 100%;
  height: 60px;
  font-size: 20px;
}

.mailer {
  text-decoration: none;
  cursor: pointer;
  color: black;
  &:hover {
    color: orange;
  }
}

.blue-button {
  background-color: #178ecb !important;
  border: 1px solid #178ecb !important;
  border-radius: 0px !important;
  color: white !important;
  cursor: pointer;
}

.red-button {
  background-color: red !important;
  border: 1px solid red !important;
  border-radius: 0px !important;
  color: white !important;
  cursor: pointer;
}

#hide-mobile {
  visibility: hidden;
}

.yellow-paint-graphic {
  display: none;
  top: 0;
  left: 0;
  z-index: 999;
  height: 100%;
  min-width: 400px;
  max-width: 400px;
  position: fixed;
  justify-content: center;
  background-image: url('https://d3qvkz8495pjoq.cloudfront.net/talkadot-bg-yellow.png');
  &__inner {
    text-align: center;
    padding: 150px 30px 0px 30px;
  }
}
.subheader {
  font-size: 12px;
  font-weight: 300;
}

.app-main-container {
  margin-top: 100px !important;
  height: 100%;
  margin: 0 auto;
  padding: 25px 50px;
  @media (max-width: $breakpoint-mobile) {
    padding: 25px 20px;
  }
}

#upper-banner-active {
  padding-top: 85px; // need to add 60px for alert banner
}

.unauth-container#upper-banner-active {
  padding-top: 0px;
}

#impersonation-banner-active {
  padding-top: 85px;
}

#low {
  z-index: 0 !important;
  visibility: hidden;
}

#upper-banner-activeimpersonation-banner-active {
  padding-top: 150px;
}

.unauth-container {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.icon-logo {
  height: 5vmin;
}

// .table-header {
//   background-color: skyblue;
//   &:hover {
//     background-color: skyblue !important;
//   }
// }

// .table-header th {
//   font-weight: bold;
// }

/* 
.table-row:nth-child(odd) {
  background-color: lightgrey;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: $breakpoint-tablet) {
  .yellow-paint-graphic {
    display: flex;
  }
  .unauth-container {
    align-items: center;
  }
}

@media (min-width: $breakpoint-mobile) {
  #hide-mobile {
    visibility: visible;
  }
  #low {
    visibility: visible;
  }
  #upper-banner-active {
    padding-top: 85px; // need to add 60px for alert banner
  }
}
