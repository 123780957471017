@import '../../App.scss';

.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 25px;
  &__inner {
    text-align: left;
    background-color: white;
  }
  &__text-content {
    text-align: left;
    margin-bottom: 10px;
  }
  &__title {
    font-size: 18px;
    margin-bottom: 20px;
    align-self: flex-start;
    font-weight: bold;
  }
  &__loader {
    margin: 0 auto;
  }
}

.card.survey-link-summaries {
  width: 100%;
}

.card.account-card {
  border: none;
}

.card.account-card.with-left-column {
  .right-column {
    width: 100%;
  }
  .card {
    &__inner {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

@media (min-width: $breakpoint-mobile) {
  .card {
    border: 1px solid black;
    &__inner {
      padding: 15px;
    }
  }
  .card.survey-link-summaries {
    display: flex;
  }
}

@media (min-width: $breakpoint-tablet) {
  .card {
    width: 49%;
  }
}
