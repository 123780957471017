.speaker-summary-header {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 20px;
  .star-icon {
    margin-right: 4px;
    width: 25px;
    height: 25px;
    margin-bottom: 3px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &__review-summary {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  &__review-count {
    color: #1197CA;
    margin-right: 3px;
    display: flex;
    align-items: center;
  }
}