@import '../../App.scss';

.onboarding {
  // display: flex !important;
  // flex-direction: row !important;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  // margin: 0 auto;
  // width: 90%;
  // height: 85%;
  // text-align: left;
  h6 {
    font-weight: 300 !important;
  }
  .MuiTypography-h6 {
    font-weight: 300 !important;
  }
  .MuiTypography-body1 {
    font-weight: 300 !important;
  }
  .avatar-upload {
    margin-top: 20px;
  }
  button {
    text-transform: none !important;
    border-radius: 3px !important;
    padding-left: 20px;
    padding-right: 20px;
  }
  .slide-download-page {
    text-align: left;
    margin-top: 20px;
  }
  .left-list {
    margin-top: 15px;
    text-align: left;
    .icon-list {
      display: flex;
      align-items: center;
      svg {
        margin-right: 7px;
      }
    }
  }
  .slide-insert-confirmation {
    text-align: center;
    margin-top: 25px;
    svg {
      max-width: 100%;
    }
    button {
      margin-top: 25px;
    }
    #skip-action {
      text-align: left;
    }
  }
  .orange {
    color: orange;
  }
  #skip-action {
    margin-top: 45px;
    cursor: pointer;
    &:hover {
      color: lightskyblue;
    }
    @media (max-width: $breakpoint-mobile) {
      margin-top: 25px;
    }
  }
  .extra-space {
    margin-top: 10px;
  }
  .welcome-video {
    &__video-container {
      margin: 30px 0px;
    }
  }
  &__step__main-action {
    margin-top: 35px;
  }
  &__step-progress {
    .MuiLinearProgress-root {
      height: 5px;
      border-radius: 3px;
      width: 200px;
      background-color: lightgrey !important;
    }
    .MuiLinearProgress-bar.MuiLinearProgress-bar1Determinate {
      background-color: orange !important;
    }
  }
  .shortlink-picker-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .full-name-form-container {
      width: 70%;
    }
    .availability-loader {
      margin-top: 30px;
      svg {
        margin-right: 5px;
      }
      &__available {
        display: flex;
        align-items: center;
        color: green;
        font-weight: 900;
      }
      &__unavailable {
        display: flex;
        align-items: center;
        font-weight: 900;
        color: red;
      }
    }
  }
  .idea-alert {
    background: #f2f2f2;
    border: 1px solid black;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center;
    svg {
      fill: orange;
    }
  }
  #highlight-qr-code {
    .speaker-slide {
      &__overlay {
        visibility: visible;
      }
    }
    .qr-code {
      position: relative;
      z-index: 300;
    }
  }
  #highlight-offer-code {
    .speaker-slide {
      &__overlay {
        visibility: visible;
      }
    }
    .offer-code {
      z-index: 300;
      .code-text {
        font-weight: bold !important;
      }
    }
  }
  .speaker-slide {
    margin: 30px auto;
    position: relative;
    &__overlay {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
      z-index: 200;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      visibility: hidden;
    }
  }
  .offer-code-form {
    text-align: center;
    margin-bottom: 60px;
    .MuiFormControl-root {
      width: 60%;
    }
  }
  .offer-upload-fieldset {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    svg {
      cursor: pointer;
      &:hover {
        fill: orange;
      }
    }
  }
  .offer-instructions {
    margin-top: 20px;
    .mini-survey-container {
      margin: 25px 0px 25px auto;
    }
  }
  .main-logo {
    max-width: 220px;
    align-self: center;
  }
  &__step-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 0.2;
    width: 100%;
    height: 90%;
    padding: 0px 10px;
  }
  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &__action-buttons {
      display: flex;
      align-items: center;
      button {
        margin-left: 5px;
      }
    }
    .back-button {
      margin-right: 10px;
      cursor: pointer;
      &:hover {
        color: orange;
      }
    }
    button {
      background: orange !important;
      border: orange !important;
      color: white !important;
    }
  }
  .full-name-form-container {
    margin-top: 30px;
    form {
      width: 100%;
      margin: 0 auto;
      input {
        font-size: 21px;
        font-weight: 300;
      }
    }
  }
  .single-social-link {
    display: flex;
    align-items: center;
    font-size: 21px;
    margin-top: 50px;
    margin-bottom: 50px;
    input {
      margin: 0;
      padding-left: 5px;
      font-size: 21px;
    }
  }
  .social-media-form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    margin: 0 auto;
  }
  .custom-url-form-container {
    width: 100%;
    .url-box {
      display: flex;
      flex-direction: column;
      margin-bottom: 50px;
      font-size: 20px;
      margin-top: 20px;
      margin-bottom: 40px;
      align-items: flex-start;
      .link-label {
        font-size: 25px;
      }
      .link-type-label {
        font-size: 16px;
        margin-bottom: 5px;
      }
      #url-builder {
        display: flex;
        align-items: center;
        input {
          font-size: 20px;
          padding-left: 5px;
        }
      }
    }
  }
  .speaker-goals-container {
    display: flex;
    width: 70%;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
    .speaker-goal {
      border: 1px solid grey;
      width: 20%;
      padding: 10px 40px;
      margin: 10px;
      cursor: pointer;
    }
    .speaker-goal.selected {
      background-color: orange;
      color: white;
    }
  }
}

@media (min-width: $breakpoint-mobile) {
  .onboarding {
    &__step-inner-content {
      width: 60%;
    }
  }
  .offer-instructions {
    .mini-survey-container {
      margin: 25px auto !important;
    }
  }
  .slide-insert-confirmation {
    .MuiTooltip-popper.MuiTooltip-popperInteractive {
      visibility: visible !important;
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .onboarding {
    padding-left: 376px;
    // width: 100%;
    // &__step-inner-content {
    //   width: 50%;
    // }
    // padding-left: 0;
  }
}
