.admin-parent {
  margin-top: 15px;
  &__header {
    background: orange;
    color: white;
    font-weight: bold !important;
    padding: 10px;
    text-align: left;
    margin-bottom: 25px !important;
  }
  svg {
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      fill: orange;
    }
  }
  &__actions {
    display: flex;
    align-items: center;
  }
  .change-alert {
    max-height: 72px !important;
    min-height: 72px !important;
    align-items: center;
  }
  .MuiDataGrid-root {
    border: none !important;
  }
  .MuiDataGrid-toolbarContainer {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .MuiDataGrid-columnHeader {
    color: white;
    font-size: 18px;
    background-color: orange;
    .MuiDataGrid-columnHeaderTitle {
      font-weight: bold !important;
    }
  }
}

.admin-navbar {
  width: 100%;
  background: skyblue;
  position: fixed;
  top: 94px;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 5px 50px;
  z-index: 100;
}

.user-settings-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
