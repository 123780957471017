@import '../../App.scss';

.account-settings {
  width: 100%;
  .integration-image {
    max-width: 200px;
  }
  .speakerflow-integration {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      background-color: orange;
      color: white;
      border-radius: 0px;
      border: none;
      margin-left: 20px;
    }
    .edit-details {
      &__container {
        width: 90%;
        text-align: left;
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }
  .avatar-container {
    align-self: flex-start;
    margin-top: 20px;
    margin-right: 50px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    &__inner {
      display: flex;
      flex-direction: column;
    }
  }
  .card {
    width: 100%;
  }
  #show-membership {
    .pricing-grid {
      margin: 0 auto 10px auto;
      text-align: left;
    }
  }
}

.edit-details {
  &__container {
    display: flex;
    flex-direction: column;
    margin: 25px auto;
    align-items: left;
    justify-content: space-between;
    #password-type {
      background: #c3e5f2;
      padding: 10px;
    }
    .MuiFormControl-root {
      margin: 0 !important;
    }
    .full-name-form-container {
      flex: 0.9;
    }
  }
  &__title {
    width: 100%;
  }
  &__title.social {
    width: 100%;
    display: flex;
    align-items: center;
  }
  &__confirm-edit {
    cursor: pointer;
    color: skyblue;
    display: flex;
    align-items: center;
    &:hover {
      color: blue;
    }
  }
  &__edit-actions {
    div {
      display: flex;
      flex-direction: row;
      color: skyblue;
      div {
        cursor: pointer;
        &:hover {
          color: blue;
        }
      }
    }
  }
}

.edit-social-media {
  display: flex;
  align-items: center;
  flex: 0.9;
  input {
    padding-left: 5px;
  }
}

#url-builder {
  display: flex;
  align-items: center;
  flex: 0.9;
  input {
    padding-left: 5px;
  }
}

#password-reset {
  display: flex;
  justify-content: space-between;
  .pword-reset {
    width: 48%;
  }
}

.avatar-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  .avatar-upload-button {
    background-color: white !important;
    color: lightskyblue !important;
    border: 1px solid lightskyblue !important;
  }
}

@media (min-width: $breakpoint-tablet) {
  .account-settings {
    .card {
      width: 70%;
      // Note: temp fix until we restructure into styled components
      &__inner {
        width: auto !important;
      }
    }
    &__content {
      &__inner {
        flex-direction: row;
      }
    }
  }
  .edit-details {
    &__container {
      flex-direction: row;
      align-items: center;
    }
    &__title {
      width: 15%;
    }
    &__title.social {
      width: 25%;
    }
  }
}
