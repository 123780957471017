@import '../../App.scss';

// TODO: Move TestimonialStatImage styling to styled components
.report-card-feedback-list {
  display: flex;
  flex: 1;
  text-align: left;
  flex-direction: column;
  margin-bottom: 30px;
  &__item-inner {
    display: flex;
    width: 100%;
    min-height: 50px;
    justify-content: space-between;
  }
  &__item.improvement {
    // Overrides the other styiles bellow on the colors
    background-color: white !important;
    margin-bottom: 5px;
  }
  &__item.dark {
    background-color: lightblue;
  }
  &__item.lite {
    background-color: aliceblue;
  }
  &__item {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 25px;
    align-items: flex-start;
    border-radius: 10px;
    padding: 15px;
    background-color: lightblue;
    #feedback-actions {
      display: flex;
      align-items: center;
      position: absolute;
      top: -5px;
      right: 0;
      cursor: pointer;
    }
    svg.quote-icon {
      width: 20px;
      height: 20px;
    }
    svg.improvement-icon {
      width: 30px;
      height: 30px;
    }
  }
}

.event-report-card {
  position: relative;
  display: flex;
  flex-direction: column;
  &__empty-report-text {
    margin: 60px auto;
    width: 60%;
  }
  &__custom-responses {
    display: flex;
    flex-direction: row;
    margin: 50px -50px 0px -50px;
    padding: 25px 50px;
    background: #f5f5f5;
    &__response-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 100%;
      text-align: left;
    }
  }
  @media (max-width: $breakpoint-mobile) {
    &__custom-responses {
      margin: 0 -20px;
      padding: 25px;
    }
  }
  &__actions {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  &__action {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    text-align: left;
    .organizer-review {
      display: flex;
      flex-direction: column;
      .review {
        &__ratings {
          margin-bottom: 15px;
        }
        &__container {
          display: flex;
          flex-direction: column;
        }
        &__field-row {
          display: flex;
          flex-direction: column;
          margin-top: 15px;
          width: 100%;
          justify-content: space-between;
          div {
            flex-grow: 0.45;
            margin-bottom: 7px;
          }
        }
        button {
          margin-top: 15px;
          margin-bottom: 15px;
          width: 100%;
        }
      }
    }
    .review-item {
      display: flex;
      flex-direction: column;
      margin-top: 45px;
      margin-bottom: 45px;
      &__star {
        height: 15px;
      }
      &__header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        div {
          margin-right: 15px;
        }
      }
    }
  }
  &__feedback-summary {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }
  &__data-summary {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 20px;
    .talk-responses {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 200px;
      justify-content: center;
      font-size: 70px;
      &__widget {
        width: 100%;
        background: #2778c2;
        color: white;
        border-radius: 5px;
        padding: 25px 10px 25px 10px;
      }
    }
    .stat-widget-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
      .engagement-progress {
        &__list {
          display: flex;
          flex-direction: column;
        }
        &__container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 275px;
          margin-bottom: 5px;
          margin-top: 12px;
        }
        &__outer-bar {
          width: 175px;
          justify-self: flex-end;
        }
        &__inner-bar {
          color: white;
          background-color: red;
          text-align: left;
          padding-left: 10px;
          font-weight: 500;
          padding-top: 2px;
          padding-bottom: 2px;
        }
      }
      svg > g > g:last-child {
        pointer-events: none;
      }
      &__widgets {
        display: flex;
        flex-direction: column;
        align-items: center;
        // Note: this may have been causing layout issues in the social share download in certain cases
        height: 100%;
      }
      &__donut-chart {
        width: 150px;
        height: 150px;
      }
      &__progress-bar-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 10px 0px;
      }
      &__progress-bar {
        width: 45px;
        margin-left: 10px;
        margin-right: 15px;
      }
      &__progress-bar-section {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }
    }
  }
  &__data-summary.profile {
    margin-top: 50px;
  }
}

.report-download-container {
  #talk-value-widget {
    flex-direction: row !important;
  }
  #reattend-widgets {
    flex-direction: row !important;
  }
  &__download-actions {
    font-weight: bold;
    display: flex;
    align-items: center;
    button {
      font-weight: bold;
      width: 100%;
    }
    #upgrade-text {
      color: white;
      border: 2px solid white;
    }
  }
}

.social-share-image.blue {
  border-color: #178ecb;
  .audience-feedback-header {
    background: #d6efff;
    color: #1197ca;
  }
}

.social-share-image.orange {
  border-color: orange;
  .audience-feedback-header {
    color: orange;
    background: #ffdead;
    width: 80%;
    font-size: 25px !important;
  }
}

.social-share-image.no-logo {
  .talk-responses {
    svg {
      margin-top: 30px !important;
    }
  }
}

.social-share-image {
  .talk-responses {
    justify-content: flex-start;
    svg {
      margin-top: 10px;
    }
  }
  .engagement-progress {
    &__container {
      margin-top: 5px !important;
    }
  }
  .event-report-card {
    &__data-summary {
      flex-direction: row;
      margin: 0;
    }
  }
  .negative-answer {
    display: none;
  }
  #total-responses {
    margin: 0 auto;
    align-items: center;
  }
  .stat-percentage {
    color: #178ecb;
    font-weight: 900;
  }
  .stat-widget-section {
    align-items: flex-start;
    margin-bottom: 15px;
    #talk-value-widget {
      flex-direction: row;
    }
    #reattend-widgets {
      flex-direction: row;
    }
    &__progress-bar-container {
      margin: 5px 0;
      min-width: 170px;
      div {
        flex: 1;
        text-align: right;
      }
    }
  }
  .widget-title {
    width: 100% !important;
    font-size: 18px !important;
  }
  .stat-percentage {
    font-size: 26px;
  }
  #talk-engagement-section {
    #talk-engagement-title {
      margin-top: 5px;
      font-size: 20px !important;
    }
  }
  #engagement-type-label {
    font-size: 16px !important;
  }
  .progress-stat-label {
    font-size: 16px;
  }
  .talkadot-logo {
    height: 105px;
    position: absolute;
    right: 0px;
    bottom: 0;
    margin-bottom: -42px;
    margin-right: -30px;
  }
  .audience-feedback-header {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 5px;
    margin-top: 15px;
    margin-bottom: 5px;
    width: 70%;
    font-weight: bold !important;
    font-size: 22px !important;
    text-align: left;
  }
  &__inner {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0px 30px;
  }
  &__banner {
    padding-top: 0px;
    display: flex;
    align-items: flex-end;
    margin-bottom: 15px;
    margin-top: -40px;
    margin-left: -35px;
    .speaker-summary-header {
      margin-bottom: 5px;
      width: 90%;
      margin-right: 5px;
      &__review-summary {
        margin: 0px;
        width: 100%;
      }
      .speaker-name {
        font-weight: 900;
        font-size: 45px;
        line-height: 45px;
      }
      .speaker-star-summary {
        font-size: 16px;
        width: 100%;
        margin-top: 5px;
        .star-icon {
          height: 22px;
          width: 22px;
        }
      }
    }
    &__avatar {
      margin-right: 10px;
    }
  }
  .report-card-feedback-list {
    justify-content: space-around;
    .feedback-content {
      font-size: 20px;
    }
  }
}

.submission-answers-parent {
  max-width: 540px;
  min-width: 400px;
  padding: 20px;
  .submission-answers {
    &__section {
      margin: 10px 0px 20px 0px;
      &__row-section {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
      }
    }
  }
  .submission-answer {
    display: flex;
    align-items: center;
  }
  svg {
    height: 40px;
    width: 40px;
  }
}

@media (min-width: $breakpoint-mobile) {
  .report-download-container {
    &__download-actions {
      position: absolute;
      top: 17px;
      right: 26px;
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .event-report-card {
    &__data-summary {
      flex-direction: row;
    }
    &__action {
      width: 40%;
      max-width: 40%;
      .organizer-review {
        .review {
          button {
            width: 60%;
          }
          &__field-row {
            flex-direction: row;
            div {
              margin-bottom: 0px;
            }
          }
        }
      }
    }
    &__custom-responses {
      &__response-container {
        width: 50%;
        max-width: 50%;
      }
    }
  }

  .stat-widget-section {
    margin-bottom: 0px;
    &__progress-bar-container {
      margin: 0px;
    }
    &__widgets {
      flex-direction: row !important;
    }
  }

  .report-card-feedback-list {
    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
      gap: 50px;
    }
  }
}
