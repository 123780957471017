.speaker-slide {
  display: flex;
  flex-direction: column;
  position: relative;
  background: white;
  font-family: Sora, sans-serif;
  .footer {
    position: absolute;
    display: flex;
    align-items: center;
  }
  .header {
    padding: 15px 40px;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    font-size: 2em;
  }
  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-left: 10%;
    padding-right: 10%;
    &__section {
      width: 50%;
    }
  }
  .code-and-contact-container {
    margin: 16px auto 0 auto;
    width: 100%;
    .offer-code {
      width: 100%;
      background-color: lightgrey;
      text-align: center;
      margin-bottom: 7px;
    }
  }
}

// 16:91
// 1920
// 1080
.speaker-slide.lg {
  min-width: 960px;
  max-width: 960px;
  min-height: 540px;
  // max-height: 540px;
  .offer-code {
    .code-text-heading {
      font-size: 0.5em;
    }
    font-size: 1.2em;
    padding-top: 8px;
    padding-bottom: 8px;
    border: 20px solid grey;
  }
  .header {
    font-size: 2em;
    padding: 30px 60px;
    font-weight: bold;
    margin-bottom: 26px;
    .subheader {
      font-size: 0.5em;
    }
  }
  .footer {
    bottom: 5px;
    right: 20px;
    img {
      height: 40px;
      margin-left: 5px;
    }
  }
  .code-text {
    font-size: 1.5em;
  }
  .content {
    .qr-code {
      margin: 15px 0px 10px 0px;
    }
    .talk-link-text {
      font-size: 1.3em;
    }
    .content-header {
      font-size: 1.5rem;
    }
  }
}

.speaker-slide.mini {
  min-width: 320px;
  max-width: 320px;
  min-height: 180px;
  border: 0.5px solid lightgray;
  .header {
    font-size: 0.8em;
    padding: 7px;
    font-weight: bold;
    margin-bottom: 7px;
    .MuiAvatar-root {
      margin-right: 7px;
    }
    .subheader {
      font-size: 8px;
    }
  }
  .code-text-heading {
    font-size: 6px;
  }
  .code-text {
    font-size: 7px;
  }
  .offer-code {
    padding-top: 3px;
    padding-bottom: 3px;
    border: 8px solid grey;
    font-size: 12px;
  }
  .content {
    .content-header {
      font-size: 0.65em;
    }
    .talk-link-text {
      font-size: 0.4em;
    }
    .qr-code {
      margin: 5px 0px 3px 0px;
    }
  }
  .footer {
    img {
      height: 14px;
      margin-left: 3px;
    }
    bottom: 1px;
    right: 5px;
    span {
      font-size: 0.4rem;
    }
  }
}

// **************************************
// Currently not in use
// Haven't updated it to the new designs
// **************************************
.speaker-slide.md {
  min-width: 640px;
  max-width: 640px;
  min-height: 360px;
  // max-height: 360px;
  .header {
    font-size: 1.5em;
    font-weight: bold;
    .subheader {
      font-size: 0.5em;
    }
  }
  .content {
    padding-left: 40px;
    padding-right: 40px;
    .content-header {
      margin-bottom: 15px;
      font-size: 1.2em;
    }
  }
}

// **************************************
// Currently not in use
// Haven't updated it to the new designs
// **************************************
.speaker-slide.sm {
  min-width: 480px;
  max-width: 480px;
  min-height: 270px;
  // border: 0.5px solid lightslategray;
  .code-text {
    font-size: 1.2em;
  }
  .header {
    font-size: 1em;
    // padding: 10px;
    // padding: 10px 30px;
    font-weight: bold;
  }
  .content {
    // padding: 20px;
    padding-left: 30px;
    padding-right: 30px;
    .content-header {
      margin-bottom: 15px;
      font-size: 0.9em;
    }
  }
}
