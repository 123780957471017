@import '../../App.scss';

.speaker-link-share {
  display: flex;
  flex-direction: row;
  // margin-top: 20px;
  // margin-bottom: 20px;
  &__icon {
    margin-right: 10px;
  }
  &__share-type {
    display: flex;
    align-items: center;
    border: 1px solid black;
    padding: 20px;
    border-radius: 10px;
    margin-right: 10px;
    a {
      text-decoration: none;
      color: inherit;
    }
    cursor: pointer;
    &:hover {
      border: 1px solid orange;
      color: orange;
    }
  }
}

.dashboard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  &__video-section {
    margin: 0 auto;
  }
  &__header {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 500;
  }
  &__container {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    width: 100%;
  }
  &__card {
    border: 1px solid black;
    padding: 15px;
  }
  .subheader {
    font-weight: 300;
  }
  @media (max-width: $breakpoint-tablet) {
    &__container {
      grid-template-columns: 1fr;
      margin-top: 24px;
    }
  }
}

.dashboard-link-summary {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px !important;
  &__link-section {
    display: flex;
    align-items: center;
    margin-left: -10px;
    margin-top: 10px;
  }
  svg {
    &:hover {
      fill: orange;
      cursor: pointer;
    }
  }
  .copyable-survey-link {
    margin: 0px 10px;
    padding: 5px 15px;
    background: #eeeeee;
    border: 1px solid black;
    cursor: pointer;
    &:hover {
      color: orange;
    }
  }
}

.code-slide-item {
  display: flex;
  flex-direction: column;
}

.survey-offers {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 10px;
  .header {
    background-color: #f0d5ad;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 20px;
  }
  &__upgrade-link {
    color: skyblue;
    cursor: pointer;
    &:hover {
      color: orange;
    }
  }
  &__offer {
    #offer-description {
      display: flex;
      align-items: center;
      #custom-question-label {
        font-weight: bold;
        background: lightgrey;
        border-radius: 50px;
        padding-left: 5px;
        padding-right: 5px;
        margin-left: 10px;
      }
    }
    #offer-code {
      flex: 0.7;
      padding-left: 2px;
    }
    div {
      flex: 1;
      // word-break: break-all;
    }
    .offer-name {
      visibility: hidden;
    }
  }
  .new-offer-button {
    width: 100%;
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    .action-icon {
      display: block;
      fill: lightgray;
      cursor: pointer;
      margin: 5px;
      &:hover {
        fill: orange;
      }
    }
  }
}

.form-fields {
  display: flex;
  flex-direction: column;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-around;
}

.survey-link-summary {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  &__header {
    text-align: left;
  }
  &__links {
    display: flex;
    flex-direction: row;
    #survey-link {
      word-break: break-all;
      margin-left: 20px;
      font-size: 30px;
      color: orange;
      font-weight: 600;
    }
  }
}

.text-feedback {
  &__title {
    color: orange;
    font-weight: bold;
    font-size: 16px;
  }
  &__content {
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 20px;
  }
}

.feedback-modal {
  // min-height: 200px;
  display: flex;
  flex-direction: column;
  .text-feedback {
    &__container {
      margin-bottom: 20px;
    }
  }
}

.summary-widgets {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  flex-direction: column;
  &__title {
    display: flex;
    margin: 20px 0 20px 0;
    font-weight: 500;
  }
  .feedback-summaries {
    display: flex;
    flex-direction: column;
    .widget-collection {
      display: flex;
      flex-direction: column;
      width: 100%;
      &__list {
        display: flex;
      }
      .summary-widget {
        display: flex;
        flex-direction: column;
        height: 120px;
        max-width: 90px;
        min-width: 90px;
        padding: 5px;
        flex: 0.5;
        background: skyblue;
        flex-wrap: wrap;
        // border: 1px solid #707070;
        margin-right: 20px;
        justify-content: space-between;
        &__header {
          text-align: left;
          font-size: 10px;
          font-weight: 300;
        }
        &__content {
          font-weight: 800;
          font-size: 24px;
        }
        &__sub-content {
          font-weight: 200;
          font-size: 10px;
        }
        &__green-percent-change {
          color: green;
          font-weight: bold;
          font-size: 10px;
        }
        &__green-percent-change::before {
          border-style: solid;
          border-width: 0.25em 0.25em 0 0;
          content: '';
          display: inline-block;
          height: 0.45em;
          left: 0.15em;
          position: relative;
          top: 0.15em;
          transform: rotate(-45deg);
          vertical-align: middle;
          width: 0.45em;
          margin-right: 0.4em;
        }
        &__red-percent-change::before {
          border-style: solid;
          border-width: 0.25em 0.25em 0 0;
          content: '';
          display: inline-block;
          height: 0.45em;
          left: 0.15em;
          position: relative;
          top: 0.15em;
          transform: rotate(135deg);
          vertical-align: top;
          width: 0.45em;
          margin-right: 0.4em;
        }
        &__red-percent-change {
          color: red;
          font-weight: bold;
          font-size: 10px;
        }
        &__content-container {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          height: 70%;
        }
      }
    }
  }
}

.events-parent {
  width: 100%;
  margin-bottom: 50px;
  margin-top: 20px;
  .MuiTableRow-root {
    cursor: pointer;
    &:hover {
      background-color: #f8f8f8;
    }
  }
  &__merge-actions {
    font-weight: bold;
    text-align: left;
    margin-top: 15px;
    color: #1197ca;
    &__action {
      display: flex;
      align-items: center;
      &:hover {
        text-decoration: underline;
        color: orange;
        cursor: pointer;
      }
    }
  }
  .event-list {
    margin-top: 20px;
    .fixed-w-xs {
      padding: 7px;
      max-width: 10px;
      overflow-wrap: break-word;
    }
    .fixed-w-s {
      max-width: 92px;
      overflow-wrap: break-word;
    }
    .fixed-w-m {
      max-width: 157px;
      overflow-wrap: break-word;
    }
    .fixed-w-ms {
      max-width: 132px;
      overflow-wrap: break-word;
    }
    .fixed-w-l {
      max-width: 212px;
      overflow-wrap: break-word;
    }
    .event-stats {
      display: flex;
      flex-direction: row;
      // align-items: center;
      justify-content: center;
      width: 85%;
      padding: 25px;
      margin: 0 auto;
      &__section {
        flex: 0.6;
        margin-right: 40px;
        display: flex;
        flex-direction: column;
      }
      &__feedback-section {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
      }
      &__title {
        color: orange;
        font-weight: bold;
        font-size: 16px;
      }
      &__text-content {
        font-weight: 300;
        font-size: 14px;
      }
      &__see-all-link {
        color: skyblue;
        cursor: pointer;
      }
      &__empty {
        text-align: center;
        margin: 25px 0px;
        h5 {
          color: orange !important;
        }
      }
    }
    .slider-answer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      .talkadot-slider {
        color: orange;
      }
      .slider-labels {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  @media (max-width: $breakpoint-mobile) {
    margin-top: 1rem;
  }
}

@media (min-width: $breakpoint-mobile) {
  .dashboard-link-summary {
    flex-direction: row;
    align-items: center;
    &__link-section {
      margin-left: 0px;
      margin-top: 0px;
    }
  }
  .survey-offers {
    .new-offer-button {
      width: 50%;
    }
    .offer-name {
      visibility: visible;
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .dashboard {
    &__container {
      flex-direction: row;
    }
  }

  .widget-collection {
    .summary-widget {
      margin-top: 0px;
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .survey-offers-form {
    .MuiDialogActions-root {
      justify-content: flex-start;
    }
    .form-fields {
      display: flex;
      flex-direction: row-reverse;
      .offer-video {
        width: 50%;
      }
    }
  }
}
