@import '../../App.scss';

.upgrade-container {
  &::-webkit-input-placeholder {
    color: 'blue' !important;
  }
  button {
    width: 100%;
  }
  .card-name {
    input {
      font-size: 14px !important;
    }
    svg {
      fill: lightgray;
    }
  }
  .subheader {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 12px;
  }
  #back-button {
    color: orange;
    cursor: pointer;
    margin-bottom: 25px;
    &:hover {
      color: darkorange;
    }
  }
  .orange-button {
    margin-bottom: 10px;
  }
  #checkout-secure {
    width: 200px;
    align-self: center;
    padding: 10px 0px;
  }
  &__payment-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 40%;
    margin-left: 30px;
    .MuiTypography-h5 {
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
  &__card-info {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid lightgray;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
  }
  &__pricing-table {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
  }
}
